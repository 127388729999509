// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-disclosure-js": () => import("./../../../src/pages/disclosure.js" /* webpackChunkName: "component---src-pages-disclosure-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-description-deep-learning-js": () => import("./../../../src/pages/jobDescriptionDeepLearning.js" /* webpackChunkName: "component---src-pages-job-description-deep-learning-js" */),
  "component---src-pages-job-description-deep-learning-speech-js": () => import("./../../../src/pages/jobDescriptionDeepLearningSpeech.js" /* webpackChunkName: "component---src-pages-job-description-deep-learning-speech-js" */),
  "component---src-pages-job-description-full-stack-js": () => import("./../../../src/pages/jobDescriptionFullStack.js" /* webpackChunkName: "component---src-pages-job-description-full-stack-js" */),
  "component---src-pages-job-description-full-stack-us-js": () => import("./../../../src/pages/jobDescriptionFullStackUS.js" /* webpackChunkName: "component---src-pages-job-description-full-stack-us-js" */),
  "component---src-pages-job-description-nlp-js": () => import("./../../../src/pages/jobDescriptionNLP.js" /* webpackChunkName: "component---src-pages-job-description-nlp-js" */),
  "component---src-pages-job-description-qa-js": () => import("./../../../src/pages/jobDescriptionQA.js" /* webpackChunkName: "component---src-pages-job-description-qa-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

